<template>
  <div style="height: 100%">
    <v-row>
      <v-col> <svg id="barcode" ref="barcode"></svg></v-col>
      <v-spacer />
      <v-col style="text-align: right">
        <div class="desc bold-font gradient-bg-1" v-if="company">
          <!---->
          {{ company.b_name }}
        </div>
        <div v-if="company" class="addr-right">
          {{ company.b_address_1 }} <span></span><br />
          {{ company.b_address_2 }} <br />
          {{ company.b_city }} <br />
          {{ company.b_country }} <br />
          <strong>TIN</strong> : {{ company.b_vat_number }}<br />
          <strong>Bank</strong> : Zenith Bank<br />
          <strong>Account</strong> : ZEN0000000000<br />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <table cellspacing="0" width="100%" class="header-table">
        <tr>
          <td width="40%" valign="top">
            <div class="light-grey">Bill To</div>
            <v-autocomplete
              v-if="canShowCompanyDropDown"
              label="Search company"
              item-text="name"
              item-value="code"
              hint="This is the company to pay the invoice"
              persistent-hint
              auto-select-first
              :v-model="toCompanyCode"
              :items="companies"
              @change="companySelected"
              clearable
              dense
              filled
            ></v-autocomplete>
            <div class="desc bold-font gradient-bg-1" v-if="toCompany">
              <!---->
              {{ toCompany.name }}
              <v-icon
                size="20"
                color="blue lighten-1"
                v-if="this.mode === 'create' && !canShowCompanyDropDown"
                @click="showCompanyDropDown = true"
                >edit</v-icon
              >
            </div>
            <div v-if="toCompany" class="addr-left">
              {{ toCompany.name }} <span></span><br />
              {{ toCompany.address_1 }} <span></span><br />
              {{ toCompany.address_2 }} <br />
              {{ toCompany.city }} <br />
              {{ toCompany.country }} <br />
              <strong>TIN</strong> : {{ toCompany.vat_number }}<br />
            </div>
          </td>
          <td width="20%" valign="top">
            <div class="light-grey">Invoice No</div>
            <div v-if="company">{{ company.i_invoice_number }}</div>
          </td>
          <td width="20%" valign="top">
            <div class="light-grey">Due date</div>
            <div v-if="company && canEditItem">
              <v-text-field type="date" />
            </div>
            <div v-else>
              2024-03-15
            </div>
          </td>
          <td width="20%" valign="top">
            <div class="light-grey">Total Amount</div>
            <div class="desc bold-font gradient-bg-1" style="font-size: 40px; line-height: 50px;">
              {{ grandTotal | currency("₦") }}
          </div>
          </td>
        </tr>
      </table>
    </v-row>
    <v-row>
      <table aria-colcount="4" class="inv-table">
        <thead class="dark-bg-1">
          <tr>
            <th>Description</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Sub Amount</th>
            <th>VAT Total</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="(item, index) in items" :key="index">
            <td>
              <div v-if="canEditItem">
                <v-autocomplete
                  label="Search Product"
                  item-text="name"
                  item-value="code"
                  persistent-hint
                  auto-select-first
                  :v-model="item.code"
                  :items="products"
                  @change="itemSelected($event, item)"
                  clearable
                  dense
                  filled
                ></v-autocomplete>
              </div>
              <div>

              </div>
            </td>
            <td>
              <div v-if="canEditItem">
                <v-text-field type="number" v-model="item.price" style="text-align: right" @change="calculateItem(item)" clearable />
              </div>
              <div v-else>
                {{ item.price | currency("₦")}}
              </div>
            </td>
            <td>
              <div v-if="canEditItem">
                <v-text-field type="number" v-model="item.quantity" style="text-align: right" @change="calculateItem(item)" clearable />
              </div>
              <div v-else>
                {{ item.quantity }}
              </div>
            </td>
            <td>
              {{ item.subTotal | currency("₦")}}
            </td>
            <td>
              {{ item.taxTotal | currency("₦")}}
            </td>
            <td>
              {{ item.total | currency("₦")}}

              <v-icon
                size="20"
                @click="deleteItem(index)"
                color="red lighten-1"
                >delete</v-icon
              >
            </td>
          </tr>
          <tr class="summary">
            <td colspan="4">
              <v-btn depressed color="primary" @click="addItem">
                Add Item
              </v-btn>
            </td>
             <td>
              Sub Total 
            </td>
            <td>
              <strong>{{ subTotal | currency("₦") }} </strong>
            </td>
          </tr>
          <tr class="summary">
            <td colspan="4">
            </td>
             <td>
              Tax Total 
            </td>
            <td>
              <strong>{{ taxTotal | currency("₦") }} </strong>
            </td>
          </tr>
          <tr class="summary">
            <td colspan="4">
            </td>
             <td>
              Total 
            </td>
            <td>
              <strong>{{ grandTotal | currency("₦") }} </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>
  </div>
</template>
<script>
import currencies from "@/data/currencies";
import companies from "@/data/companies";
import products from "@/data/products";
import QueryService from "@/services/query.service";
import JsBarcode from "jsbarcode";

export default {
  props: {
    code: {
      default: null,
      type: String,
    },
    mode: {
      default: "create",
      type: String,
    },
  },
  components: {},
  data() {
    return {
      showCompanyDropDown: true,
      loading: false,
      toCompanyCode: null,
      toCompany: null,
      companies,
      products,
      invoice: null,
      invoiceCurrency: {
        symbol: "$",
        name: "US Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "USD",
        name_plural: "US dollars",
      },
      taxRate: 20,
      discountRate: 0,
      itemTemplate: {
        code: null,
        name: null,
        price: 0.0,
        quantity: 1,
        taxRate: 7.5,
        taxTotal: 0.0,
        subTotal: 0.0,
        total: 0.0,
      },
      items: [],
      currencies: currencies,
      company: null,
      client: "Client information",
      invoiceDate: "",
    };
  },
  methods: {
    async companySelected(code) {
      this.showCompanyDropDown = false;
      this.setToCompany(code);
    },
    itemSelected(code, item) {
      const product = this.products.find((item) => item.code === code)
      // const item = this.items[index];
      item.code = product.code;
      item.price = product.price;
      item.vat_rate = product.vat_rate;
      item.quantity = 1;
      this.calculateItem(item);
      // console.log(product, index);
    },
    calculateItem(item) {
      item.subTotal = item.price * item.quantity;
      item.taxPercentage = item.taxRate ? item.taxRate / 100 : 0.0;
      item.taxTotal = item.subTotal ? item.subTotal * item.taxPercentage : 0.0;
      item.total = item.subTotal ? item.subTotal + item.taxTotal : 0.0;
    },
    setToCompany(code) {
      try {
        this.toCompany = this.companies.find((item) => item.code === code);

      } catch (error) {
        //
      }
    },
    showBarCode() {
      // alert("hey");
      JsBarcode(this.$refs.barcode, this.company.i_invoice_number, {
        height: 40,
        width: 1,
      });
    },
    async loadInvoice() {
      this.loading = true;
      try {
        let invoiceNumber = this.code;
        if (!invoiceNumber) {
          invoiceNumber = this.$route.params.code;
        }
        const sql = `SELECT 
        i.id as i_id, 
        i.business_code as i_business_code, 
        i.invoice_number as i_invoice_number, 
        i.amount as i_amount, 
        i.currency as i_currency, 
        i.vat_amount as i_vat_amount, 
        i.invoiced_to as i_invoiced_to, 
        i.status as i_status, 
        i.created_date as i_created_date, 
        i.paid_date as i_paid_date, 
        i.description as i_description,
        b.id as b_id, 
        b.code as b_code, 
        b.name as b_name, 
        b.vat_number as b_vat_number, 
        b.email as b_email, 
        b.phone_number as b_phone_number, 
        b.address_1 as b_address_1, 
        b.address_2 as b_address_2, 
        b.city as b_city, 
        b.country as b_country
        FROM invoices i 
        left join business b on b.code = i.business_code where i.invoice_number = '${invoiceNumber}'`;

        const payload = {
          sql,
        };

        const response = await QueryService.sql(payload);
        this.company = response.data[0];
        this.showBarCode();
        this.initiate();
      } catch (error) {
        //
      }
      this.loading = false;
    },
    addItem: function () {
      this.items.push({ ...this.itemTemplate });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
    },
    decimalDigits: function (value) {
      return (
        this.invoiceCurrency.symbol +
        " " +
        value.toFixed(this.invoiceCurrency.decimal_digits)
      );
    },
    printInvoice: function () {
      window.print();
    },
    adjustTextAreaHeight: function (event) {
      var el = event.target;
      el.style.height = "1px";
      el.style.height = 25 + el.scrollHeight + "px";
    },
    initiate: function () {
      if (this.mode === "create") {
        this.addItem();
      } else {
        //
        this.toCompany = this.companies[0];
        this.addItem();
        this.itemSelected(this.products[0].code, this.items[0]);
      }
    },
  },
  computed: {
    canEditItem() {
      return this.mode === "create";
    },
    canShowCompanyDropDown() {
      return this.showCompanyDropDown && this.mode === "create";
    },
    subTotal: function () {
      const total = this.items.reduce(function (accumulator, item) {
        return accumulator + item.subTotal;
      }, 0);
      return total;
    },
    discountTotal: function () {
      const total = this.subTotal * (this.discountRate / 100);
      return total;
    },
    taxTotal: function () {
      const total = this.items.reduce(function (accumulator, item) {
        return accumulator + item.taxTotal;
      }, 0);
      return total;
    },
    grandTotal: function () {
      const total = this.subTotal + this.taxTotal;
      return total;
    },
  },
  mounted() {
    this.loadInvoice();
  },
};
</script>
<style scoped>
.header-table td {
  padding-right: 20px;
}
.gradient-bg-1 {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#42b883),
    to(#1a2327)
  );
  background-image: linear-gradient(90deg, #42b883, #1a2327);
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.bold-font {
  font-weight: 700 !important;
}
.big-font {
  font-size: 3rem;
}
.addr-right {
  color: #a4a4a4;
  text-align: right;
}
.addr-left {
  text-align: left;
}
.light-grey {
  color: #a4a4a4;
}
.inv-table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}
.inv-table,
.inv-table thead {
  margin-top: 2rem;
}
.dark-bg-1 {
  background-color: #42b883 !important;
  color: #eee;
}
.inv-table,
.inv-table thead {
  margin-top: 2rem;
}

.inv-table thead tr th {
  padding: 2rem 0.5rem;
  border-top: none;
  color: #eee !important;
  background-color: inherit !important;
  font-weight: 700;
  margin-bottom: 1rem;
  border-bottom: 1px;
}
.inv-table td {
  padding: 10px;
}
</style>